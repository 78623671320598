.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #121212;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 600px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.history-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    max-height: 400px;
    overflow-y: auto;
}

.history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #1e1e1e;
    border-radius: 5px;
    border: 1px solid #333;
}

.actor-info {
    flex: 1;
    font-size: 0.9rem;
    color: #ddd;
}

.game-stats {
    flex: 1;
    font-size: 0.9rem;
    text-align: center;
    color: #ddd;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.actions button {
    padding: 5px 10px;
    font-size: 0.9rem;
    color: #fff;
    background: #007bff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.actions button:hover {
    background: #0056b3;
}

button {
    margin-top: 20px;
    padding: 10px;
    font-size: 1rem;
    color: #fff;
    background: #444;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background: #666;
}