/* CustomGamesModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #1a1a1a;
    color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 80vh;
}

.custom-games-grid {
    margin-top: 10px;
}

.grid-header, .grid-row {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #444;
}

.grid-header {
    font-weight: bold;
    background-color: #222;
}

.grid-row {
    align-items: center;
}

.grid-row span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.actions {
    display: flex;
    gap: 10px;
}

.actions button {
    padding: 5px 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.actions button:hover {
    background-color: #0056b3;
}

.modal-actions {
    margin-top: 20px;
    text-align: right;
}

.modal-actions button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-actions button:hover {
    background-color: #0056b3;
}
