:root {
    --bg-color: #121212;
    --text-color: #e0e0e0;
    --primary-color: #bb86fc;
    --secondary-color: #03dac6;
    --error-color: #cf6679;
    --border-color: #292929;
}

body {
    background-color: var(--bg-color);
    color: var(--text-color);
    margin: 0;
    font-family: 'Arial', sans-serif;
}

button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: var(--secondary-color);
}

input {
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Root CSS */
:root {
    --menu-margin: 20px;
    --profile-spacing: 10px;
}

/* Profile Dropdown */
.profile-dropdown {
    position: absolute;
    top: var(--profile-spacing);
    right: var(--profile-spacing);
}

/* Main Menu */
.main-menu {
    margin-left: var(--menu-margin);
    align-self: center;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 10px; /* Add spacing between buttons */
}

.main-menu button {
    width: 150px; /* Optional: Set a consistent width for buttons */
    align-self: flex-start; /* Align buttons to the left within the column */
}


/* Profile Dropdown Styling */
.profile-container {
    position: relative;
    display: inline-block;
}

.profile-button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.profile-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    min-width: 150px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.profile-menu button {
    background-color: transparent;
    color: var(--text-color);
    border: none;
    text-align: left;
    padding: 10px 15px;
    width: 100%;
    cursor: pointer;
}

.profile-menu button:hover {
    background-color: var(--secondary-color);
}

/* Show dropdown menu when parent has 'active' class */
.profile-container.active .profile-menu {
    display: block;
}



.actor-info h2 {
    margin: 0;
    font-size: 1.5rem;
}

.actor-info button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.actor-info button:hover {
    background-color: var(--secondary-color);
}


.actor-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

/* Hint Button */
.hint-button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    align-self: flex-start; /* Align button with actor text */
}

.hint-button:hover {
    background-color: var(--secondary-color);
}

/* Responsive Hint Button */
@media (max-width: 600px) {
    .hint-button {
        display: none;
    }
}

.hint-image:hover {
    transform: scale(1.50);
}


.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    max-width: 100%; /* Allow grid to shrink with the screen size */
    width: calc(150px * 10 + 20px * 9); /* Ensure grid can grow to fit 10 items max */
    justify-content: center;
    margin: 0 auto;
}


.grid-item {
    text-align: center;
    cursor: pointer;
    width: 150px; /* Maintain consistent width for items */
}

.grid-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.grid-item.tv-item img {
    clip-path: polygon(20% 0, 80% 0, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0 80%, 0 20%);
}


.grid-item h3 {
    margin: 10px 0 5px;
}

.grid-item p {
    margin: 0;
    font-size: 12px;
    color: gray;
}



.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9999; /* Ensure it's the topmost layer */
    /*//width*/
    width: 200px;

}

.dropdown-menu button {
    background: none;
    border: none;
    color: var(--text-color);
    padding: 5px 0;
    cursor: pointer;
    text-align: left;
    width: 100%;
}

.dropdown-menu button:hover {
    background-color: var(--secondary-color);
}


.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 30000; /* Ensure it's on top of everything */
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-top-color: var(--primary-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px; /* Add space between spinner and text */
}

.loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.2);
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
    z-index: 1500; /* Ensure it is above all other content */
}

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.loading-text {
    color: white;
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

button:disabled {
    background-color: gray;
    cursor: not-allowed;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: #233c59;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal h2 {
    margin-bottom: 20px;
    text-align: center;
}

.option {
    margin-bottom: 15px;
}

.option label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
}

.modal-content {
    width: 450px;
    height: 650px; /* increased height */
    display: flex;
    flex-direction: column;
    background-color: #233c59; /* your modal background */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-body {
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.hint-modal {
    position: fixed;
    top: calc(50% + 60px); /* Add offset to account for GameBar height */
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(126, 123, 123);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 2000; /* Ensure it is above GameBar and other elements */
    max-width: 90%;
    max-height: calc(80% - 60px); /* Adjust max height to fit screen better */
    overflow-y: auto; /* Handle overflow for long movie lists */
    transition: top 0.3s ease, max-height 0.3s ease; /* Smooth adjustment */
}

.hint-modal.shrink {
    top: calc(50% + 40px); /* Adjust offset for smaller GameBar */
    max-height: calc(80% - 40px); /* Reduce height for smaller GameBar */
}

.hint-modal h3 {
    text-align: center;
    margin-bottom: 20px;
}

.hint-modal .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
    justify-items: center;
}

.hint-modal .grid-item img {
    width: 100px;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
}

/* Adjust grid for smaller screens */
@media (max-width: 600px) {
    .hint-modal .grid {
        grid-template-columns: repeat(2, 1fr); /* Reduce to 2 columns */
    }
}


/* Game Header */
/*.game-header {*/
/*    position: sticky;*/
/*    top: 0;*/
/*    z-index: 999;*/
/*    background-color: var(--bg-color);*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    padding: 1px 20px;*/
/*    transition: transform 0.3s ease, height 0.3s ease;*/
/*    height: 120px; !* Default height *!*/
/*}*/

/*.game-header.shrink {*/
/*    height: 60px; !* Shrink height *!*/
/*    transform: scale(0.9);*/
/*}*/


/* Move Back Button */
button {
    transition: transform 0.3s ease, font-size 0.3s ease;
}

button.shrink {
    transform: scale(0.8);
}


.top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.game-title {
    font-size: 1.5rem;
    margin: 0;
    transition: font-size 0.3s ease;
}

/* Actor Section */
.actor-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8px;
    width: 100px;
}
.actor-section.shrink {
    transform: scale(0.75); /* Shrink proportionally */
    transform-origin: top center; /* Shrink from the top */
}


/* Parent Container */
.target-actor-section {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100px;
    margin-right: 100px; /* Add space between target and last step sections */
    transform-origin: top center; /* Anchor the shrink effect */
}

/* Image Container */
.actor-image-container {
    position: relative; /* Relative positioning for counter alignment */
    display: inline-block; /* Keeps image and counter together */
    width: 90px;
    height: 135px;

}


/* Target Actor Image */
.target-actor-section img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    object-fit: cover;
    transition: all 0.3s ease;
}

/* Shrink Effect */
.target-actor-section.shrink {
    transform: scale(0.75); /* Shrink the entire section */
}


.starting-actor-section {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100px;
}

.starting-actor-section img {
    width: 47px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}

.starting-actor-section.shrink {
    transform: scale(0.75); /* Shrink proportionally */
    transform-origin: top center; /* Shrink from the top */
}

.actor-name {
    font-size: 1rem;
    margin: 0;
}

/* Actor Image */
.actor-image {
    width: 92.5px;
    height: 139px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.actor-image.shrink {
    width: 70px; /* Shrink proportionally */
    height: 105px;
}

.actor-image:hover {
    transform: scale(1.50);
}


.hint-button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

.hint-button:hover {
    background-color: var(--secondary-color);
}

.close-button {
    background-color: transparent;
    border: none;
    font-size: 1.2rem; /* Adjusted font size */
    font-weight: bold;
    cursor: pointer;
    color: var(--primary-color); /* Adjusted to match the overall theme */
    /*padding: 0; !* Remove extra padding *!*/
}

.close-button:hover {
    color: var(--secondary-color);
}

.hint-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px; /* Reduced padding for a sleeker look */
    background-color: var(--light-bg-color); /* Use a lighter background */
    border-bottom: 1px solid var(--border-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for better distinction */
    overflow: hidden; /* Prevent clipping issues */
}

/* Steps Section */
/* Steps Section */
.steps-container {
    flex: 1; /* Expands to fill available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto; /* Scrollable when content overflows */
    padding: 0 20px;
    gap: 5px;
}

.steps-container.shrink {
    height: 75%; /* Shrink only the height */
    transform-origin: top; /* Shrink from the top */
    transition: height 0.3s ease; /* Smooth shrinking animation */
}


.steps-section {
    display: flex;
    flex-wrap: wrap-reverse; /* Fill rows starting from the right */
    gap: 5px; /* Reduce gap for closer alignment */
    justify-content: flex-end; /* Align items to the right */
    align-items: center;
    max-height: 120px; /* Adjust height for scrolling */
    height: 120px; /* Ensure consistent height */
    overflow-y: auto; /* Enable vertical scrolling when needed */
    padding-right: 10px; /* Add padding to maintain spacing from the right edge */
}

.steps-section.shrink {
    max-height: 70px;
    gap: 3px; /* Reduce gap further when shrinking */
}
/* Steps Display */
.steps-display {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px; /* Initial gap between items */
    margin-top: 20px;
    height: 120px; /* Fixed height for consistency */
    overflow: hidden; /* Prevent overflow */
    position: relative;
    transform-origin: center;
}

.steps-display.shrink {
    height: 70px; /* Shrink container height */
    gap: 3px; /* Reduce gap for closer alignment */
}

/* Step Item */
.step-item {
    width: 80px;
    height: 120px;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.steps-display.shrink .step-item {
    width: 60px; /* Shrink width proportionally */
    height: 90px; /* Shrink height proportionally */
}

/* Animation for visible items */
.step-item.appear {
    transform: translateX(0);
    opacity: 1;
}

/* Responsive Scaling */
@media (max-width: 600px) {
    .steps-display {
        height: 90px;
    }

    .step-item {
        width: 60px;
        height: 90px;
    }
}

/* Score Animation */
@keyframes score-animation {
    0% {
        transform: scale(1);
        color: #fff;
    }
    50% {
        transform: scale(1.2);
        color: var(--primary-color);
    }
    100% {
        transform: scale(1);
        color: #fff;
    }
}

/* Bonus or Deduction */
.score-change {
    animation: score-animation 0.8s ease;
    font-size: 1.5rem;
}

/* Responsive Design */
@media (max-width: 600px) {
    .step-item {
        width: 60px;
        height: 90px;
    }
}

.step-item img {
    object-fit: cover;
    border-radius: 5px; /* Default border radius for movie images */
}

/* Step Stats Section */
.step-stats {
    display: flex;
    flex-direction: row; /* Ensure items are side by side */
    justify-content: center; /* Center all items horizontally */
    align-items: center; /* Align items vertically */
    gap: 10px; /* Adjust spacing between items */
    width: 100%; /* Ensure the section spans full width */
    padding: 10px 0; /* Add optional padding for aesthetics */
}

.step-stats.shrink .timer-section,
.step-stats.shrink .step-count {
    font-size: 1rem; /* Shrink font size when needed */
}

.step-count {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    transition: font-size 0.3s ease;
}

/* Last Step Section */
.last-step-section {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100px;
    margin-right: 30px; /* Ensure consistent spacing */
}
.last-step-section p {
    margin: 0;
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    transition: font-size 0.3s ease;
}

.last-step-section.shrink {
    transform: scale(0.75); /* Shrink proportionally */
    transform-origin: top center; /* Shrink from the top */
}

.last-step-name {
    margin: 0;
    font-size: 1rem;
    /*margin-bottom: 5px;*/
    white-space: nowrap;
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    text-align: center;
    transition: font-size 0.3s ease;
}

.last-step-name.shrink {
    font-size: 1rem; /* Shrink font size */
/*    hide teh name*/
    display: none;
}

.last-step-section img {
    width: 70px;
    height: 105px;
    border-radius: 8px;
}

.last-step-image.shrink {
    width: 70px;
    height: 105px;
}

/* Glimmer Effect */
.last-step-image-container.glimmer-effect {
    position: relative;
    display: inline-block;
    animation: glimmer 1.5s infinite;
}
/* Tooltip Styling */
.last-step-image-container {
    position: relative;
}

.last-step-image-container:hover::after {
    content: attr(title);
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    white-space: nowrap;
    z-index: 10;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

/* Back Moves Count */
.back-moves-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #007bff;
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 5;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes glimmer {
    0% {
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    }
    50% {
        box-shadow: 0 0 20px rgba(0, 123, 255, 0.8);
    }
    100% {
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    }
}

/* Attention Effect */
.last-step-section.attention-effect {
    animation: shake 0.5s infinite alternate;
}

@keyframes shake {
    0% {
        transform: translateX(-2px);
    }
    100% {
        transform: translateX(2px);
    }
}

.actor-item img {
    width: 40px;
    height: 60px;
    border-radius: 50%; /* Makes the image an oval */
    object-fit: cover;
}

.movie-item img {
    width: 80px;
    height: 120px;
    border-radius: 5px; /* Keeps movie images rectangular */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 600px) {
    .steps-section {
        justify-content: flex-start; /* Align items to the start for smaller screens */
    }

    .step-item {
        width: 60px; /* Reduce size for smaller screens */
        height: 90px;
    }
}

/* Timer Section */
.timer-section {
    font-size: 1.5rem;
    font-weight: bold;
    color: white; /* Ensure visibility on dark background */
    text-align: right;
    flex-grow: 1; /* Allow the timer to expand */
}

.timer-section.shrink {
    font-size: 1rem; /* Reduce font size when shrinking */
}

.move-back-button {
    position: relative;
    font-size: 14px;
    /*padding: 5px 15px;*/
    margin-bottom: 5px;
    border-radius: 4px;
    transition: all 0.3s ease;
}

/* Game Bar */
.game-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: black;
    color: white;
    height: 170px; /* Default height */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: height 0.3s ease, padding 0.3s ease;
}

.game-bar.shrink {
    height: 120px; /* Shrinks from bottom up */
    padding: 5px 20px; /* Reduce padding to align with smaller height */
}

/* Game Container */
.game-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 120px; /* Match default height of GameBar */
    transition: padding-top 0.3s ease;
}

.game-bar.shrink + .game-container {
    padding-top: 60px; /* Match shrunk height of GameBar */
}

.hamburger-menu {
    position: absolute; /* Position relative to its parent */
    top: 0px; /* Adjust vertical position */
    right: 50px; /* Adjust horizontal position to stay inside the screen */
    cursor: pointer;
    font-size: 24px;
    z-index: 1000; /* Ensure it appears above other elements */
}
/* Hamburger Menu */
/*.hamburger-menu {*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*    font-size: 24px;*/
/*}*/
.option div {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.option label {
    margin-right: 10px;
}

.modal input[type="range"] {
    width: 100%;
}

.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #000;
    color: #fff;
    font-size: 1.5rem;
}

.grid-controls {
    margin-bottom: 20px;
    margin-right: 14px;
    /* Remove negative margin to avoid misalignment */
    text-align: right;
    position: relative; /* Ensure positioning is controlled */
    top: -20px; /* Use positioning instead of margin */
}

.grid-controls button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    position: relative; /* Ensure button positioning is controlled */
    z-index: 1; /* Ensure button stays on top of other elements */
}

.grid-controls button:hover {
    background-color: #0056b3;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(38, 37, 37, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #000000;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.search-bar input {
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-bar button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-bar button:hover {
    background-color: #0056b3;
}

.selection-grid-container {
    max-height: 400px; /* Set a maximum height for the grid */
    overflow-y: auto;  /* Enable vertical scrolling */
    border: 1px solid #ccc; /* Optional: Add a border for visual clarity */
    padding: 10px; /* Optional: Add some padding */
    margin: 10px 0;
}

.selection-grid {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap within the container */
    gap: 10px; /* Add space between items */
    justify-content: flex-start;
}

.selection-grid-item {
    flex: 0 1 calc(33.33% - 10px); /* Adjust item width for a 3-column layout */
    box-sizing: border-box; /* Include padding/border in the width calculation */
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(56, 53, 53, 0.71);
}

.selection-grid-item img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 5px;
}

.selection-actions {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.selection-actions button {
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.selection-actions button:hover {
    background-color: #0056b3;
}

/* Hint Counter */
.hints-count {
    position: absolute;
    top: -5px; /* Fine-tune for alignment */
    right: -5px; /* Fine-tune for alignment */
    background-color: #ffd700;
    color: #000;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 1; /* Ensure it’s above the image */
}


/* Parent container for the row */
.option-row {
    display: flex;
    align-items: center;
    gap: 16px; /* Add spacing between items */
}

/* Individual options */
.option-row .option {
    display: flex;
    flex-direction: column; /* Stack label and input vertically */
}

/* Styling for labels */
.option-row .option label {
    font-size: 14px;
    margin-bottom: 4px; /* Add spacing between label and input */
}

/* Styling for the dropdown and checkbox */
.option-row .option input[type="checkbox"] {
    margin-left: 8px; /* Add spacing after label for the checkbox */
}

.option-row .option select {
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    max-height: 100px;
}


.path-display {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    margin-top: 20px;
    max-height: 120px; /* Adjust height for scrolling */
    height: 120px;
    position: relative;
}

.path-spot {
    width: 80px;
    height: 120px;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f0f0f0; /* Default background */
    border: 2px dashed rgba(0, 0, 0, 0.2); /* Dashed border for empty spots */
    position: relative;
    font-size: 12px;
}

.path-spot.filled {
    border: 2px solid var(--secondary-color); /* Highlight filled spots */
    background-color: #ffffff; /* Filled background */
}

.path-spot.mandatory img {
    filter: grayscale(100%);
}
.path-spot.mandatory .mandatory-info {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 0px;
    font-size: 12px;
    border-radius: 0 0 4px 4px;
}

.path-spot:hover {
    transform: scale(1.7) translateY(20%); /* Expand and shift downward */ /* Hover effect to expand */
    z-index: 10;
}

.path-spot.mandatory img:hover{
    filter: none;
}

.path-spot img {
    object-fit: cover;
    border-radius: 4px;
    width: 80px;
    height: 120px;
}
/*actor path-spot*/
.path-spot.actor-spot {
    border-radius: 50%;
}

.actor-spot img {
    border-radius: 50%; /* Makes actor images round */
}

.movie-spot img {
    border-radius: 5px; /* Keeps movie images rectangular */
}

.path-info {
    text-align: center;
    font-size: 10px;
    color: #555;
}

.path-info p {
    margin: 2px 0;
}

.path-spot.genre-bonus {
    border: 2px solid gold; /* Highlight for genre bonus */
    background-color: #fffbea;
}

.path-info .bonus-label {
    color: gold;
    font-weight: bold;
}

.grid-item.bonus-highlight {
    border: 2px solid gold; /* Highlight bonus movies */
    background-color: #fffbea;
    color: #2c2c2c;
}

.grid-item .bonus-label {
    color: gold;
    font-weight: bold;
    margin-top: 5px;
}

/* Omitted Items */
.grid-item.omitted {
    opacity: 0.5; /* Make omitted items appear faded */
    pointer-events: none; /* Prevent interaction with omitted items */
    border: 2px dashed gray; /* Dashed border for omitted items */
}

.grid-item.omitted img {
    filter: grayscale(100%); /* Grayscale for omitted item images */
}

/* Required Items */
.grid-item.required {
    border: 3px solid #007bff; /* Blue border for required items */
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.7); /* Glow effect */
}

.grid-item.required img {
    border-radius: 5px; /* Optional: Slight rounding for image */
}


/*.game-stats-popup {*/
/*    background: #fff;*/
/*    border-radius: 10px;*/
/*    padding: 20px;*/
/*    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);*/
/*    max-width: 600px;*/
/*    margin: auto;*/
/*    text-align: center;*/
/*    position: relative;*/
/*}*/


.game-stats-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--bg-color);
    color: var(--text-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 12200;
    width: 80%;
    max-width: 1200px;
    box-sizing: border-box;
    text-align: center; /* Center-align all text in the popup */
}
.score-display {
    margin: 20px 0; /* Add spacing above and below the score */
    font-size: 3rem; /* Increase the font size for the score */
    font-weight: bold; /* Make the score bold */
    color: var(--primary-color); /* Apply a primary color to the score */
}

.game-stats-popup .stats-actions {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    gap: 10px; /* Add spacing between buttons */
    margin-top: 20px; /* Add some space above the buttons */
}

.game-stats-popup button {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.game-stats-popup button:hover {
    background-color: var(--secondary-color);
}

.bonuses-list {
    margin: 20px 0;
    text-align: left;
    max-height: 200px;
    /*max-width: 300px;*/
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #2f3448;

    /* Flexbox for left-to-right stacking and wrapping */
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Spacing between bonus items */
}

.bonus-item {
    padding: 10px;
    border-radius: 6px;
    background: #2d572c;
    color: #ffffff;
    font-size: 14px;
    animation: slideIn 0.5s ease;

    /* Flex item properties */
    max-width: 300px; /* Ensure individual items don't exceed the container's width */
    flex: 1 1 auto; /* Allow items to grow and wrap */
    box-sizing: border-box; /* Include padding and border in size */
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.stats-actions {
    margin-top: 20px;
}

.stats-actions button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: white;
    cursor: pointer;
    transition: background 0.3s;
}

.stats-actions button:hover {
    background: #0056b3;
}

.floating-scores {
    position: absolute;
    top: 20%;
    left: 75%;
    transform: translateX(-50%);
    pointer-events: none; /* Prevent mouse interaction */
}

.floating-score {
    position: absolute;
    font-size: 2rem;
    font-weight: bold;
    animation: floatUp 2s ease-out forwards;
    opacity: 1;
}

.floating-score.positive {
    color: green;
}

.floating-score.negative {
    color: red;
}

@keyframes floatUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-50px);
    }
}

.action-message {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.9); /* Example color */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    font-size: 1rem;
    cursor: pointer;
    animation: fadeInOut 5s ease-in-out;
}

@keyframes fadeInOut {
    0%, 80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}




/* UserSearch.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* .modal-content {
    background: #1a1a1a;
    color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 80vh;
} */

.user-search-grid {
    margin-top: 10px;
}

.grid-header, .grid-row {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #444;
}

.grid-header {
    font-weight: bold;
    background-color: #222;
}

.grid-row {
    align-items: center;
}

.grid-row span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.actions {
    display: flex;
    gap: 10px;
}

.actions button {
    padding: 5px 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.actions button:hover {
    background-color: #0056b3;
}

.actions button:disabled {
    background-color: #444;
    cursor: not-allowed;
}

.modal-actions {
    margin-top: 20px;
    text-align: right;
}

.modal-actions button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-actions button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-top: 10px;
}


/*for socla modual*/
.tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.tabs button {
    padding: 10px 20px;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.tabs button.active {
    background-color: #007bff;
}

.friends-list, .requests-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.requests-grid, .friends-list {
    margin-bottom: 10px;
}

.grid-row {
    display: flex;
    justify-content: space-between; /* Ensure spacing between name and button */
    align-items: center;
    padding: 10px;
    background-color: #1a1a1a;
    border-bottom: 1px solid #444;
}

.request-name {
    flex-grow: 1; /* Allow the name to take up all available space */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent text wrapping */
}

.revoke-button {
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    margin-left: 20px; /* Add some space between the name and button */
}

.revoke-button:hover {
    background-color: #0056b3;
}

.grid-row .actions {
    display: flex;
    gap: 10px;
}

button {
    padding: 5px 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

button:disabled {
    background-color: #444;
    cursor: not-allowed;
}





/* Profile Dropdown */
.profile-dropdown {
    position: absolute;
    top: var(--profile-spacing);
    right: calc(var(--profile-spacing) + 50px); /* Move slightly left to create space for the social button */
}

/* Social Button Container */
.social-button-container {
    position: absolute;
    top: var(--profile-spacing);
    right: var(--profile-spacing);
}

/* Social Button */
.social-button {
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Adjust width to fit the image */
    height: 40px; /* Adjust height to fit the image */
}

.social-button img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the image scales properly */
    border-radius: 50%; /* Optional: Make the button circular */
}

/* Notification Badge */
.notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    font-size: 0.8rem;
    border-radius: 50%;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; /* Ensure the badge doesn't interfere with button clicks */
}
/* for buttons wiht notifacion icons attached */
.notifications-button {
    position: relative;
}


.requests-header {
    flex: 0 0 auto;
    margin-bottom: 10px;
}

.requests-scroll-container {
    flex: 1;
    overflow-y: auto;
}


.requests-section {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.request-item {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    background-color: #444;
}

.request-details {
    /* Style details of the request as needed */
    font-weight: bold;
    margin-bottom: 5px;
}

.request-item .actions {
    display: flex;
    flex-direction: row;
    gap: 10px; /* Adjust spacing as needed */
    justify-content: center;
}

.modal-overlay.challenge-modal {
    z-index: 100001;
}

/* For the challenges grid (6 columns) */
.challenges-content .grid-header,
.challenges-content .grid-row {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr 0.8fr 0.8fr 0.8fr 0.5fr;
    align-items: center;
}

/* For the replay grid (7 columns) */
.replay-content .grid-header,
.replay-content .grid-row {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.5fr;
    align-items: center;
}

/* LeaderBoard Modal Specific Styles */

/* LeaderBoard Modal Overlay */
.leaderboard-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 35000; /* ensures it overlaps other modals */
}

/* LeaderBoard Modal Content */
.leaderboard-modal-content {
    background-color: var(--bg-color);
    color: var(--text-color);
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 35001;
}

/* Leader Board Game Details */
.leaderboard-game-details {
    margin-bottom: 20px;
}

.leaderboard-game-details h3 {
    margin-bottom: 10px;
}

/* Leader Board Player Rank */
.leaderboard-player-rank {
    margin-bottom: 20px;
}

.leaderboard-player-rank h3 {
    margin-bottom: 10px;
}

/* Leader Board Grid */
.leaderboard-grid {
    margin-bottom: 20px;
}

.leaderboard-grid h3 {
    margin-bottom: 10px;
}

/* Leader Board Grid Header & Rows */
.leaderboard-grid-header,
.leaderboard-grid-row {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;  /* Rank, Name, Score */
    padding: 10px 0;
}

.leaderboard-grid-header {
    border-bottom: 1px solid var(--border-color);
    font-weight: bold;
}

.leaderboard-grid-row {
    border-bottom: 1px solid var(--border-color);
}

/* New modifier class for Game Options Modal */
.modal-overlay.game-options {
    background: rgba(0, 0, 0, 0.7); /* Darker background */
}

.modal.game-options {
    background: #1d2a3a;
    border: 1px solid var(--border-color);
    padding: 20px; /* Ensure enough padding */
}

/* Add media query for small screens */
@media (max-width: 600px) {
    .modal.game-options {
        max-height: 90vh; /* Limit height to viewport */
        overflow-y: auto; /* Enable vertical scrolling */
        width: 90%; /* Adjust width as needed */
    }
}