.custom-game-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    min-height: 100vh;
    background-color: #1e1e1e;
    color: #fff;
}

.custom-game-page h1 {
    margin-bottom: 20px;
    font-size: 2rem;
    text-align: center;
    color: #fff;
}

.custom-game-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 800px;
    width: 100%;
    justify-content: center;
}

.actor-selection,
.settings-section {
    flex: 1 1 300px;
    background: #2c2c2c;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.actor-selection img {
    width: 100px;
    height: auto;
    border-radius: 8px;
    margin-top: 10px;
}

.settings-section label {
    display: block;
    margin-bottom: 10px;
    color: #fff;
}

.action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.action-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.action-buttons button:hover {
    background-color: #0056b3;
}
